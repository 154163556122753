@import url(../../css/themeSettings.css);

.search-div {
    display: inline-block;
    position: relative;
    width: 15vw;
    min-width: 200px;
}



.header-icon {
    padding: 0 10px;
}

.seperator {
    border: .5px solid lightgrey;
    margin: 10px;
}

.top-nav-item {
}

    .top-nav-item:hover {
        cursor: pointer;
        background: var(--light-grey-hover);
        color: black;
    }

.add-item {
    border-top: .5px solid lightgrey !important;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
}

    .add-item:first-child {
        border-top: unset !important;
    }

    .add-item:hover {
        cursor: pointer;
        background-color: lightgrey;
    }

/*@media screen and (max-width: 1180px) {
    .top-nav-item-desc {
        display: none;
    }
}*/

#candidatesDashboardBtn.active, #candidatesDashboardBtn.active:hover {
    background-color: firebrick;
}

.dropdown:hover, .dropbtn:hover {
    background-color: var(--light-grey-hover);
}

@media screen and (max-width: 1450px) {
    span.top-nav-item-desc {
        display: none;
    }
}


/* Dropdown Button */


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--light-grey);
    
    min-width: 225px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
}


.subTabs {
    color: var(--dark-text);
}
    /* Links inside the dropdown */
    .dropdown-content li {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

        /* Change color of dropdown links on hover */
        .dropdown-content li:hover {
            background-color: var(--light-grey-3);

            cursor: pointer;
        }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.text-icon {
    color: var(--primary-pink) !important;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    color: var(--dark-text);
}

    .separator::before, .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid pink;
    }

    .separator::before {
        margin-right: .25em;
    }

    .separator::after {
        margin-left: .25em;
    }

.secondary-header {
    position: absolute;
    top: 18px;
    left: 0;
    z-index: 1;
    background-color: var(--light-pink-to-greyscale);
    color: rgb(0, 51, 102) !important;
    border-color: unset !important;
    padding: 10px;
    width: 100%;
}

.left-nav {
    background-color: var(--light-pink-to-greyscale);
    padding-right: 0;
    padding-left:0;
}

.left-nav .nav-link {
    color: #000 !important;
}

    .left-nav .nav-link.active {
        background-color: #fff;
        color: #F30079 !important;
    }

    .left-nav .nav-link:hover {
        color: #F30079;
    }

.top-nav-item.active {
    background-color: var(--light-pink);
}

    .top-nav-item.active .top-nav-item-desc {
        color: #000;
    }