@import url(themeSettings.css);

a:hover {
    cursor: pointer;
}

.inline-button {
    position: absolute;
    top: 8px;
    right: 5px;
    transition: right 0.2s;
    width: 50px;
    color: var(--dark-text);
}

.search-inline-button {
    position: absolute;
    top: 10px;
    right: 15px;
    transition: right 0.2s;
    color: var(--dark-text);
}

.inline-button:hover {
    cursor: pointer;
}

.background-pink {
    background: pink;
}

body.modal-open {
    overflow: visible !important;
}

.modal-backdrop {
    z-index: 1048 !important;
}

.modal {
    z-index: 1049 !important;
}

.search-menu {
    position: absolute;
    transform: translate3d(0px, 55px, 0px) !important;
    top: 10px;
    right: 0px !important;
    left: unset !important;
    will-change: transform;
    min-width: 500px;
    width: 35vw;
}

.search-menu-left {
    position: absolute;
    transform: translate3d(0px, 55px, 0px) !important;
    top: 10px;
    left: 0px !important;
    left: unset !important;
    will-change: transform;
    min-width: 500px;
    width: 35vw;
}

.color-light-grey {
    color: lightgray;
}

.color-pink, .text-primary {
    color: #F30079 !important;
}

.panel-content {
    margin: 15px;
}

.checkbox-panel-content .row {
    margin-bottom: 15px;
}

.no-border {
    border: 0 !important;
    background: transparent;
}

.nav-menu li a {
    display: flex;
    align-items: center;
    outline: 0;
    padding: 0.8125rem 2rem;
    font-size: 0.875rem;
    color: #bdafd1 !important;
    font-weight: 400;
    text-decoration: none;
    position: relative;
}

.nav-menu li > ul li a {
    color: #af9fc7 !important;
    padding: 0.8125rem 2rem 0.8125rem 4rem !important;
}

.dropdown-menu.show {
    transition: .5s;
    -webkit-animation: animateFadeInUp .5s;
    -webkit-animation-name: bounce;
    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: 10;
    -webkit-animation-direction: alternate;
}

.pinkTextA {
    color: var(--pink-to-white) !important;
}

.pinkTextA:hover {
    cursor: pointer !important;
}

.nav-menu li span{
    display: flex;
    align-items: center;
    outline: 0;
    padding: 0.8125rem 2rem;
    font-size: 0.875rem;
    color: #fff !important;
    font-weight: 400;
    text-decoration: none;
    position: relative;
}

.nav-menu li span > [class*='fa-'], .nav-menu li span > .ni {
    margin-right: 0.25rem;
    font-size: 1.125rem;
    width: 1.75rem;
    color: #876fab;
    text-align: left;
}

    .nav-menu li span:hover, .nav-menu li.active > span:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

.nav-menu li.active > span {
    color: white;
    background-color: rgba(255, 255, 255, 0.04);
    box-shadow: inset 3px 0 0 #dd5293;
    font-weight: 400;
    color: #fff !important;
    padding-left: 75px;
}

.nav-footer .nav-footer-buttons > li > span {
    display: block;
    color: #9782b7;
    height: 1.8125rem;
    line-height: 1.8125rem;
    margin-top: 1px;
    padding: 0 13px;
    overflow: visible;
    font-size: 1rem;
}

.nav-menu li.active:not(.open) {
    background: #24b3a4;
}

.nav-menu li.active:not(.open) > span:before {
    content: '\f413';
    font-family: 'nextgen-icons';
    position: absolute;
    top: calc(50% - 5px);
    right: 11px;
    font-size: 7px;
    height: 10px;
    width: auto;
    color: #24b3a4;
    display: flex;
    align-content: center;
    align-items: center;
}

.nav-menu li span.collapsed-hidden:hover, .nav-menu li.active > span.collapsed-hidden:hover,
.nav-menu li span.collapsed-reveal:hover, .nav-menu li.active > span.collapsed-reveal:hover,
.nav-menu li span.ml-auto:hover{
    background: unset;
}

.nav-menu li span .ml-auto,
.nav-menu li span .collapsed-hidden,
.nav-menu li span .collapsed-reveal {
    padding: 0;
}

    .nav-menu li span .collapsed-hidden i,
    .nav-menu li span .collapsed-reveal i {
        text-align: right;
        margin-right: 0;
    }

.nav-menu li > span {
    padding-left: 75px;
    color: #bdafd1 !important;
}

input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    z-index: 1;
}

.family-history-content .row, .personal-history-content .row,
.family-history-content .row, .personal-history-content .row {
    margin-bottom: 15px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: rgba(211,41,121, .5);
    background-color: #d32979;
}



.translateLeftNav {
    -webkit-transform: translate3d(0,0,0) !important;
    transform: translate3d(0,0,0) !important;
}

.checkbox-margin {
    margin-top: 30px;
}

@media only screen and (max-width: 992px) {
    .page-content-overlay.yes {
        background: rgba(0,0,0,.09);
    }

     .page-content-overlay.yes {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.09);
    }

    .page-content-overlay {
        background: 0 0;
        -webkit-transition: background .3s;
        transition: background .3s;
        position: fixed;
        z-index: 1001;
    }

    .checkbox-margin {
        margin-top: unset;
    }
}



.breadcrumb {
    background: unset;
}

.modal-transparent .modal-content {
    background: rgba(73,26,48,.85);
}

.modal-transparent .modal-content {
    -webkit-box-shadow: 0 1px 15px 1px rgba(168,33,96,.3);
    box-shadow: 0 1px 15px 1px rgba(168,33,96,.3);
}

.leftNav-tinyLogo:hover, .page-logo:hover {
    cursor: pointer;
}

.info-card {
    background-image: linear-gradient(transparent, pink 5%, transparent 100%);
    padding: 0;
}

.Toastify__toast--success {
    background: #008900 !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.toast {
    position: fixed !important;
    top: 60px !important;
    right: 0px !important;
    width: 250px;
    margin: 15px;
    font-size: 12px !important;
    z-index: 1;
}

    .toast.show {
        z-index: 1;
    }

.checkmark {
    font-size: 20px;
    color: green;
    align-content: center;
    align-self: center;
    height: 100%;
}

.cancel {
    font-size: 20px;
    color: red;
    align-content: center;
    align-self: center;
    height: 100%;
}

.slide-pane__overlay {
    z-index: 11111;
}


.btn-submit {
    color: #fff;
    background-color: rgba(243, 0, 121, 1);
    border-color: rgba(243, 0, 121, 1);
}

    .btn-submit:hover {
        color: #fff;
        background-color: hotpink;
        border-color: hotpink;
    }

    .btn-submit:focus, .btn-submit.focus {
        color: #fff;
        background-color: rgba(243, 0, 121, 1);
        border-color: rgba(243, 0, 121, 1);
    }

    .btn-submit.disabled, .btn-submit:disabled {
        color: #fff;
        background-color: #1dc9b7;
        border-color: #1dc9b7;
    }

    .btn-submit:not(:disabled):not(.disabled):active, .btn-submit:not(:disabled):not(.disabled).active,
    .show > .btn-submit.dropdown-toggle {
        color: #fff;
        background-color: #179c8e;
        border-color: #159184;
    }

        .btn-submit:not(:disabled):not(.disabled):active:focus, .btn-submit:not(:disabled):not(.disabled).active:focus,
        .show > .btn-submit.dropdown-toggle:focus {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
        }

.btn.alert-info:hover {
    color: #004085 !important;
    text-decoration: none;
    border-color: #004085 !important;
}

.rw-btn-select {
    display: none;
}

.rw-multiselect .rw-input-reset {
    height: unset !important;
    width: unset !important;
    padding: unset !important;
}


.rw-widget-input, .rw-filter-input {
    box-shadow: unset !important;
}

/*.rw-list-option.rw-state-focus{
    background: unset !important;
    color: unset !important;
    border: unset !important;
}*/

.rw-list-option.rw-state-focus:hover {
    background-color: #2196F3 !important;
    color: white !important;
    border: 2px solid black !important;
}

    .rw-list-option:hover, .rw-list-option:hover.rw-state-focus {
        background-color: #2196F3 !important;
        color: white !important;
        border: 2px solid black !important;
    }

.rw-multiselect-tag {
    margin-top: unset !important;
    margin-left: unset !important;
    margin-right: calc(0.279335em - 1px);
}

.rw-list-empty, .rw-list-option, .rw-list-optgroup {
    min-height: 30px;
}

.slide-pane-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.25rem;
    height: 90%;
    overflow-y: auto;
    line-height: 2rem;
}

.panel-hdr h2 {
    color: #F30079 !important;
    font-size: large;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-style: double;
}

.rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
    background-color: #337ab7;
    border-color: #337ab7;
    color: white;
}

.patientContainer {
    overflow-x: hidden;
    height: calc(100vh - 460px);
}

.patientContainerBig {
    overflow-x: hidden;
    height: calc(100vh - 250px);
}

.fixedExamFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.unstyleBtn {
    border: 0;
    background: inherit;
    font-size: 100%;
    font-family: inherit;
    padding: 0;
}

.popover {
    z-index: 11112;
}

.table-custom-hover tbody tr:hover {
    color: #212529;
    background-color: #fdfdfd;
    cursor: default;
    z-index: 1;
}

.hover {
    cursor: pointer;
}

.slide-pane__content {
    overflow-y: hidden !important;
}

.modal-footer {
    padding: 0 !important;
}

.highlight {
    animation: none !important;
}

.optionContainer .option {
    background: unset !important;
    color: #000;
}

.optionContainer .option:hover {
    background: pink !important;
}

.chip {
    background: pink !important;
}

.not-required {
    font-weight: inherit;
}

hover:hover {
    cursor: pointer;
}

.modal-popup .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-popup .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem !important;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.btn:disabled {
    color: lightgrey !important;
    background-color: #6c757d;
    border-color: #6c757d;
    opacity: .65;
    cursor: not-allowed;
}
#divSmallBoxes {
    z-index: 111111111 !important;
}

.flag-scroll-div {
    height: 100px;
    overflow: auto;
    /* direction: rtl; */
    border-right: solid 12px transparent;
}

hover:hover {
    cursor: pointer;
}

.hide {
    display: none !important;
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: pink;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
}

    .custom-ui > button {
        width: 160px;
        padding: 10px;
        border: 1px solid #fff;
        margin: 10px;
        cursor: pointer;
        background: none;
        color: #fff;
        font-size: 14px;
    }

.react-confirm-alert-overlay {
    position: fixed;
    top: 100px !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1111111;
    background: rgba(255, 255, 255, 0.9);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: unset !important;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.mwlPopover {
    margin: unset !important;
    margin-top: 7px !important;
}

    .mwlPopover .react-datepicker-popper {
        left: unset !important;
        right: 25px;
    }

    .smooth-dnd-container {
        width: 100%;
    }

.horizontal-divider {
    border-bottom: 1px solid #F30079;
    width: 100%;
    margin: 15px 25px;
}

.text-transparent {
    color: transparent;
}

.mw-50 {
    min-width: 50%;
}

.custom-nested-dropdown span {
    float: right;
    margin-top: 8px;
}

.ant-picker-dropdown {
    z-index: 1111111 !important;
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(243, 0, 121, .2) !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: rgb(243, 0, 121) !important;
}

.ant-picker:hover, .ant-picker-focused, .ant-select:hover, .ant-select-focused, .ant-select-multiple:hover, .ant-select-multiple-focused {
    border-color: rgb(243, 0, 121) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: rgb(243, 0, 121) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: rgba(255,192,203,.4) !important;
}

.ant-select {
    padding: 0 !important;
    display: inline-block !important;
}

.ant-picker-range {
    display: inline-flex !important;
}

.ant-message .anticon {
    vertical-align: unset !important;
    bottom: 0 !important;
}

.ant-message-error .anticon {
    color: #721c24;
}

.ant-message-error {
    background: #f8d7da;
    padding: 10px 16px;
}

.ant-message-notice-content {
    background: unset !important;
    padding: unset !important;
}

.ant-message-success {
    background: #d4edda;
    padding: 10px 16px;
}
.left-nav .nav-link:hover {
    color: #F30079 !important;
}

.mh-32 {
    min-height: 32px;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: rgba(243, 0, 121, 1);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 0, 121, .25);
}

.ant-spin-spinning {
    color: rgb(243, 0, 121) !important;
}

.ant-spin-spinning.text-white {
    color: #fff !important;
}

fieldset {
    border-width: 2px !important;
    border-style: groove !important;
    border-color: rgba(243, 0, 121, .15) !important;
    border-image: initial !important;
    padding: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

legend {
    color: rgba(243, 0, 121, 1) !important;
    width: unset !important;
}

.react-datepicker-popper {
    min-width: 350px !important;
}

.ant-select-dropdown {
    z-index: 111111 !important;
}

.ant-upload.ant-upload-drag {
    height: 150px !important;
}

.anticon-check-circle {
    color: #F30079 !important;
}

.display-third {
    height: 33vh;
    overflow-y: auto;
}

.smallPane {
    width: 25% !important;
}

.medPane {
    width: 50% !important;
}

@media screen and (max-width: 1500px) {
    .smallPane {
        width: 50% !important;
    }

    .medPane {
        width: 75% !important;
    }
}

@media screen and (max-width: 800px) {
    .smallPane, .medPane {
        width: 100% !important;
    }
}

.timeout-modal .anticon {
    color: var(--primary-pink) !important;
}

.timeout-modal .ant-btn-primary {
    background: var(--primary-pink) !important;
    border-color: var(--primary-pink) !important;
}

#upload-photo {
    opacity: 0;
    z-index: -1;
}

.mh-150 {
    max-height: 150px;
}

.icon-in-label {
    position: absolute;
    right: 20px;
    top: 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

.custom-fancy-dropdown {
    display: block !important;
}

.custom-fancy-dropdown div {
    width: 100%;
}

    .custom-fancy-dropdown button span {
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        border-top: 4px dashed #666;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        /* margin-left: 8px; */
        /* margin-left: auto; */
        float: right;
        margin-top: 8px;
    }

.custom-fancy-dropdown > div > div {
    overflow: auto;
    height: 300px;
}

.slide-pane .slide-pane__title {
    max-width: 100% !important;
}

.w-60 {
    width: 60% !important;
}

.text-hr {
    display: flex;
    align-items: center;
    text-align: center;
}

    .text-hr::before,
    .text-hr::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }

    .text-hr:not(:empty)::before {
        margin-right: .25em;
    }

    .text-hr:not(:empty)::after {
        margin-left: .25em;
    }

.refresh-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #fff;
    top: 0;
    opacity: .7;
}