@import url(themeSettings.css);

.figure {
    font-size: 2.2rem;
    line-height: 2rem;
    font-weight: 700;
    padding-top: 1rem;
}

.count-type {
    font-size: 1.05rem;
    line-height: .9rem;
    text-align: right;
}

.left-card-content {
    display: inline-block;
    padding: .5rem 2.2rem;
    font-weight: 300;
    vertical-align: middle;
    border-bottom: none;
    text-align: right;
    width: 49%;
    height: 100%;
}

.card {
    margin: 20px 0;
}

.card-body {
    padding: 5px 7px;
}

.list-group-flush .list-group-item {
    border-top: .5px solid grey;
}

.pinkCard {
}

    .pinkCard .editIcon {
        color: rgba(243, 0, 121, 1);
    }

        .pinkCard .editIcon:hover {
            color: deeppink;
        }


    .pinkCard .card-header, .pinkCard .modal-header, .card-column-header {
        background-color: rgba(243, 0, 121, .15);
        color: rgba(243, 0, 121, 1);
        padding: .45rem .5rem .35rem;
        letter-spacing: .05rem;
        font-size: 1.05rem;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        border-bottom: solid 3px rgba(243, 0, 121, 1);
    }

    .pinkCard .card-body {
        border-top: 0;
        border-radius: 0 0 4px 4px;
    }

    .pinkCard .custom-card-label {
        font-weight: 700;
    }

.modal-header {
    padding: 20px !important;
}

.pinkCard .slide-pane__header {
    background-color: #ededed;
    color: rgba(243, 0, 121, 1);
    padding: .45rem .5rem .35rem;
    letter-spacing: .05rem;
    font-size: 1.05rem;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    border-bottom: solid 3px rgba(243, 0, 121, 1);
}

.slide-pane .slide-pane__title {
    font-weight: 700 !important;
}

.d-card {
    margin: 10px;
    padding: 15px;
}

.d-card span {
    width: 100%;
}

.card-container {
    border-bottom: 1px solid #ccc;
    margin: 3px;
    padding: 10px;
}

.smooth-dnd-container.horizontal {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}