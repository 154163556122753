@import url(../../css/themeSettings.css);

/* Ricky Custom */
.background {
    background-image: linear-gradient(to top right, var(--background-gradient-color) 20%, white 50%, var(--background-gradient-color) 90%);
    height: 100vh;
}
/* ACCESSIBILITIES */
/* SHORTCUT BUTTON (appears on bottom right of the page) */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");

body {
    font-size: 0.8125rem;
    letter-spacing: 0.1px;
}

.page-content {
    color: #666666;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    font-weight: 400;
}

strong {
    font-weight: 500;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
    font-weight: 300;
    display: block;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 2px 0 1.5rem;
}

h2 small,
h3 small,
.h2 small,
.h3 small {
    font-size: 0.9375rem;
}

h4 small,
.h4 small {
    font-size: 0.875rem;
}

h5 small,
h6 small,
.h5 small,
.h6 small {
    font-size: 0.8125rem;
}

/* contrast text */
.text-contrast {
    color: #333333;
}

/* text-gradient */
.text-gradient {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #6e4e9e), color-stop(50%, #62468d), color-stop(75%, #0c7cd5), to(#0960a5));
    background: linear-gradient(180deg, #6e4e9e 25%, #62468d 50%, #0c7cd5 75%, #0960a5 100%);
    color: #886ab5;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}


/* Page Layout Starts below...
========================================================================== */
html body {
    background: #faf8fb;
    /* NEW*/
}

    html body .blankpage-logo {
        height: 4.125rem;
        width: 16.875rem;
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        min-height: 1px;
        padding: 0 2rem;
    }

    html body .blankpage-form-field {
        position: fixed;
        top: 45%;
        left: 50%;
        width: auto;
        min-width: 320px;
        height: auto;
        transform: translate(-50%, -50%);
    }

        html body .blankpage-form-field .card {
            margin: 0;
            padding: 13.5px 20px 15.5px;
            -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }

        html body .blankpage-form-field .blankpage-btn {
            font-size: 1rem;
            font-weight: 400;
            padding: 10px 15px;
            margin: 10px 0 5px;
        }

    html body .blankpage-footer {
        padding: 10px 0;
        font-size: 0.75rem;
        color: #73579d;
    }

    html body .login-footer {
        color: rgba(255, 255, 255, 0.9);
        font-size: 0.6875rem;
    }

/*html body video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background: url("../img/clouds.png") no-repeat;
        background-size: cover;
        -webkit-transition: 1s opacity;
        transition: 1s opacity;
    }*/

@media screen and (max-device-width: 800px) {
    /*html body html {
        background: url("../img/clouds.png") #FFF no-repeat center center fixed;
    }*/

    html body #bgvid {
        display: none;
    }
}

/*# sourceMappingURL=page-login.css.map */

.form-group li:not(.rw-list-option, .rw-multiselect-tag) {
    margin-bottom: .5rem;
}

.blankpage-form-field .page-logo img {
    width: auto;
    height: auto;
}