:root {
    --primary-pink: #F30079;
    --light-pink: #FFE6EA;
    --text-color: #666;
    --bg-color: #fff;
    --light-grey: #ededed;
    --light-grey-hover: lightgrey;
    --light-grey-3: #ddd;
    --dark-text: #000;
    --text-danger: #B00020;
    --almost-transparent: rgba(0, 0, 0, 0.125);
    --white-to-grey: #fff;
    --primary-link: #1B4B79;
    --background-gradient-color: rgba(255, 192, 203, .5);
    --pink-to-greyscale: #a12861;
    --pink-to-white: #a12861;
    --light-pink-to-greyscale: #FFE6EA;
    --warning-color: #fff8e9;
}

[data-theme="dark"] {
    --text-color: #fff;
    --bg-color: #292929;
    --light-grey: #3F3F3F;
    --light-grey-hover: #3F3F3F;
    --light-grey-3: #8b8b8b;
    --dark-text: #fff;
    --text-danger: #CF6679;
    --almost-transparent: rgba(255, 255, 255, 0.125);
    --white-to-grey: #3F3F3F;
    --primary-link: #fff;
    --background-gradient-color: rgba(0, 0, 0, .5);
    --pink-to-greyscale: #666;
    --pink-to-white: #fff;
    --light-pink-to-greyscale: #666;
    --warning-color: #3F3F3F;
}
